import AlertMessage from "../../../layout/AlertMessage";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import Dropdown from "../../../layout/Dropdown";

export default ({ values, setFieldValue, goTo }) => {
	//Filter out any event series that are not on the same site.
	const SiteID = values.siteId;
	const rawEventSeries = values.eventSeries;
	const filteredEventSeries = rawEventSeries.filter((eventSeries) =>
		eventSeries.Sites.some((site) => site.SiteID === SiteID)
	);

	return {
		id: "EventSeries",
		summary: [
			{
				id: "EventSeries",
				label: "Event Series",
				value: (
					<React.Fragment>
						<span style={{ textWrap: "auto" }}>
							{values?.selectedEventSeries
								?.map((event) => event.label)
								.join(", ") || ""}
						</span>{" "}
					</React.Fragment>
				),
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "EventSeries");
					goTo("configuration");
				},
			},
		],
		render: () => (
			<React.Fragment>
				{values.conflictRateErrorMessage && (
					<AlertMessage
						text={values.conflictRateErrorMessage}
						borderColor="red"
						backgroundColor="red"
						textColor="white"
					/>
				)}
				<StepTitle>Which event series will this rate apply to?</StepTitle>
				<Dropdown
					isMulti={true}
					options={filteredEventSeries}
					value={values.selectedEventSeries}
					onChange={(selected) => {
						setFieldValue("selectedEventSeries", selected);
					}}
					placeholder="Select event series"
				/>
			</React.Fragment>
		),
		isValid: values.startTimestamp < values.endTimestamp,
	};
};
