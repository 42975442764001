import React, { useEffect, useState } from "react";
import * as installationApi from "../../../api/installation";
import * as steps from "./steps";
import Alert from "react-s-alert";
import WizardLayout from "../WizardLayout";
import { useTranslation } from "react-i18next";
import {
	useUpsertEventGroup,
	useGetEventSeriesByGroupId,
	useGetGroupById,
} from "./data";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import {
	initialGroup,
	getHoursData,
	handleApiError,
	getTitleByMode,
	uniqueSites,
} from "./GroupWizard.helpers";
import { getGroupValues } from "./GroupWizard.validations";
import { usePermissions } from "../../../hooks";

/**
 * Groups wizard component.
 *
 * @param {object} props
 * @param {object} props.group
 */
export default function GroupsWizard(props) {
	const canManageEvents = usePermissions(null, "ManageEvents", true);

	const { t: translate } = useTranslation();
	const [siteSpaces, setSiteSpaces] = useState([{}]);
	const [eventSeries, setEventSeries] = useState();
	const [isLoadingSitesAndSpaces, setIsLoadingSitesAndSpaces] = useState(
		props.mode === "update"
	);
	const [isLoadingEventSeries, setIsLoadingEventSeries] = useState(true);

	const { sites, isLoading: isGroupsLoading } = useGetGroupById(
		props.group?.OrganizationAccessGroupID
	);

	const {
		eventSeries: eventSeriesRawFromAPI,
		isLoading: isGettingEventSeriesFromAPI,
	} = useGetEventSeriesByGroupId(props.group?.OrganizationAccessGroupID);

	useEffect(() => {
		if (props.mode !== "update" || isGroupsLoading || !sites) {
			return;
		}

		const uniqueSiteList = uniqueSites(sites);

		setSiteSpaces(
			uniqueSiteList.flatMap(({ SiteID, Name, LeaseParks }) =>
				LeaseParks && LeaseParks.length > 0
					? LeaseParks.map(({ LeaseParkID, Name: leaseParkName }) => ({
							selectedSite: {
								value: SiteID,
								label: Name,
							},
							selectedSpace: {
								value: LeaseParkID,
								label: leaseParkName,
							},
					  }))
					: [
							{
								selectedSite: {
									value: SiteID,
									label: Name,
								},
								selectedSpace: {
									value: null,
									label: null,
								},
							},
					  ]
			)
		);
		setIsLoadingSitesAndSpaces(false);
	}, [isGroupsLoading, sites]);

	useEffect(() => {
		if (props.mode === "update" && props.group) {
			if (!isGettingEventSeriesFromAPI && eventSeriesRawFromAPI) {
				setEventSeries(
					eventSeriesRawFromAPI.map((series) => ({
						value: series.EventSeriesID,
						label: series.Name,
					}))
				);
				setIsLoadingEventSeries(false);
			}
		} else {
			setIsLoadingEventSeries(false);
		}
	}, [isGettingEventSeriesFromAPI, eventSeriesRawFromAPI]);

	const organizationId = props.selectedOrganization.OrganizationID;
	let _steps = [];

	if (props.mode === "add") {
		_steps = [steps.editStep];
	} else if (props.mode === "update") {
		_steps = [steps.editStep];
	} else if (props.mode === "remove") {
		_steps = [steps.removeStep];
	}

	const [eventGroupValues, setEventGroupValues] = useState();

	const upsertEventGroup = useUpsertEventGroup(
		{
			...eventGroupValues,
			eventGroupId: props.group?.OrganizationAccessGroupID,
		},
		{
			onCompleted: () => {
				Alert.success("Access group saved");
				props.close(true);
			},
			onError: () => {
				Alert.error(translate("Errors.Generic"));
			},
		}
	);

	if (
		isGroupsLoading ||
		isLoadingSitesAndSpaces ||
		isLoadingEventSeries ||
		isGettingEventSeriesFromAPI
	) {
		return <LoadingPlaceholder />;
	}

	const handleSubmit = async (values) => {
		if (values.mode === "add" || values.mode === "update") {
			let accountId = values.account ? values.account.value : null;

			//Reduce lease rates to lease rate IDs and filter out the placeholder
			let leaseRateIds = [];

			if (values.leaseRates) {
				leaseRateIds = values.leaseRates.reduce((arr, item) => {
					if (item.value === -1) {
						return arr;
					} //Do not include the 'no rate' item
					arr.push(item.value);
					return arr;
				}, []);
			}

			let locationBasedNodeIds = [];
			if (values.locationBasedNodes) {
				locationBasedNodeIds = values.locationBasedNodes.map(
					(item) => item.value
				);
			}

			let restrictedAccessNodeIds = [];
			if (values.restrictedAccessNodes) {
				restrictedAccessNodeIds = values.restrictedAccessNodes.map(
					(item) => item.value
				);
			}
			let hoursData = getHoursData(values, initialGroup);

			if (values.isEvent) {
				setEventGroupValues({
					name: values.name,
					leaseRateIds,
					siteIds: values.siteSpaces.map((s) => s.selectedSite.value),
					leaseParkIds: values.siteSpaces
						.filter((s) => s.selectedSpace)
						.map((s) => s.selectedSpace.value),
					allowWhitelistedPlates: values.allowWhitelistedPlates,
					allowMultiEntry: values.allowMultiAccess,
					isEnforcementExempt: values.isEnforcementExempt,
					hasANPRAccess: Boolean(values.group.HasANPRAccess),
					waiveConvenienceFee: Boolean(values.group.WaiveConvenienceFee),
				});
				upsertEventGroup();
				return;
			}

			let data = {
				Name: values.name,
				ForceSessionlessAccessOnSites: values.sessionlessAccess,
				SpaceAccountID: accountId,
				siteIds: values.siteSpaces.map((s) => s.selectedSite.value),
				leaseParkIds: values.siteSpaces
					.filter((s) => s.selectedSpace)
					.map((s) => s.selectedSpace.value),
				leaseRateIds,
				locationBasedNodeIds,
				restrictedAccessNodeIds,
				IsEnforcementExempt: values.isEnforcementExempt,
				...hoursData,
			};

			try {
				if (values.mode === "add") {
					await installationApi.createOrganizationAccessGroup(
						organizationId,
						data
					);
					Alert.success("Access group created");
				} else if (values.mode === "update") {
					data = {
						...data,
						HasANPRAccess: values.group.HasANPRAccess,
						WaiveConvenienceFee: values.group.WaiveConvenienceFee,
					};
					await installationApi.updateOrganizationAccessGroup(
						organizationId,
						props.group.OrganizationAccessGroupID,
						data
					);
					Alert.success("Access group updated");
				}
				props.close(true);
			} catch (error) {
				handleApiError(error, translate);
			}
		} else if (props.mode === "remove") {
			try {
				await installationApi.deleteOrganizationAccessGroupById(
					organizationId,
					props.group.OrganizationAccessGroupID
				);
				Alert.success("Access group removed");
				props.close(true);
			} catch (error) {
				if (error.errors[0].code === "GroupHasAssociatedRate") {
					Alert.error("Cannot remove group, group has associated rate.");
				} else {
					Alert.error(translate("Errors.Generic"));
				}
			}
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title={(values) => getTitleByMode(values.mode)}
			values={getGroupValues(props, siteSpaces, eventSeries, canManageEvents)}
			onSubmit={handleSubmit}
			steps={_steps}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
