import * as helper from "./helper";
import {
	BookInAdvanceStep,
	DurationStep,
	GroupStep,
	MinimumDurationStep,
} from "./shared-steps";
import { StepText, StepTitle } from "../WizardLayout";
import { dailyFeeRange, money as moneyFormat } from "../../../helpers/format";
import AlertMessage from "../../layout/AlertMessage";
import Button from "../../layout/Button";
import { DURATION_TYPES } from "../../../helpers/constants";
import DatePicker from "../../layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import FieldError from "../../layout/FieldError";
import InfoBox from "../../layout/InfoBox";
import InputWithIcon from "../../layout/InputWithIcon";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import Toggle from "../../layout/Toggle";
import WizardNavigation from "../WizardNavigation";
import moment from "moment";
import { isNil, sortBy } from "lodash";

const _footer = (
	values,
	previous,
	goTo,
	next,
	isFirstStep,
	disableNext = () => {
		return false;
	},
	keyStrokeHandler
) => {
	const showNavigation = !values.editFromSummary && values.mode === "add";

	const reviewAction = { key: "review", text: "Review", next: "summary" };
	let right = showNavigation
		? { key: "next", text: "Next", next: next }
		: reviewAction;

	return (
		<WizardNavigation
			leftItems={[
				showNavigation && !isFirstStep && (
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>
				),
			]}
			rightItems={[
				<Button
					disabled={disableNext()}
					key={right.key}
					onClick={() => goTo(right.next)}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					{right.text}
				</Button>,
			]}
		/>
	);
};

export const nonConsecutiveBooking = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "nonConsecutiveBooking",
	label: "Day booking type",
	render: () => {
		return (
			<div>
				<StepTitle>
					Would you like to allow for Non-Consecutive Booking?
				</StepTitle>
				<MultiChoiceButton
					selected={!values.nonConsecutiveBooking}
					onClick={() => {
						setFieldValue("nonConsecutiveBooking", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.nonConsecutiveBooking}
					onClick={() => {
						setFieldValue("nonConsecutiveBooking", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"name",
			true,
			() => false,
			keyStrokeHandler
		),
});

//Individual step definitions

export const name = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => {
		return (
			<div>
				<StepTitle>
					What is the name for this{" "}
					{helper.getUnitLabel(values.durationType, "period", "lower")} rate?
				</StepTitle>
				<EditableInputField
					type="text"
					name="name"
					onChange={(event) => setFieldValue("name", event.target.value)}
					value={values.name}
					useLabel="Name"
					error={errors.name ? errors.name : ""}
				/>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"isValidationRate",
			values.durationType !== "Days",
			() => errors.name || !values.name,
			keyStrokeHandler
		),
});

export const isValidationRate = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "isValidationRate",
	label: "Validation Rate",
	render: () => {
		return (
			<div>
				<StepTitle>Is this a validation rate?</StepTitle>
				<MultiChoiceButton
					selected={!values.isValidationRate}
					onClick={() => {
						setFieldValue("isValidationRate", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.isValidationRate}
					onClick={() => {
						setFieldValue("isValidationRate", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"monthlyFee",
			true,
			() => false,
			keyStrokeHandler
		),
});

export const monthlyFee = ({
	touched,
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => {
	let label = "Fee";
	let stepLabel = "fee";
	let inputLabel = "Fee per Unit";

	const defaultHourlyBillableDuration = {
		value: 60,
		label: "1 Hour",
	};
	const options = [
		{ value: 30, label: "30 Minutes" },
		defaultHourlyBillableDuration,
	];

	const dayLabels = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
	];

	let next = "bookInAdvance";
	if (values.durationType === "Event") {
		next = "eventSeriesSelectionStep";
	}

	return {
		id: "monthlyFee",
		label: label,
		render: () => {
			return (
				<div>
					<StepTitle>What is the {stepLabel} for this rate?</StepTitle>
					<InputWithIcon
						icon="DollarSign"
						type="number"
						name="monthlyFee"
						align="left"
						value={values.monthlyFee}
						useLabel={inputLabel}
						onFormat={(value) => setFieldValue("monthlyFee", value)}
					/>
					<FieldError
						showError={touched.monthlyFee && errors.monthlyFee}
						errorText={errors.monthlyFee}
					/>
					{values.durationType === "Hours" && (
						<div style={{ display: "flex", flexDirection: "column" }}>
							<StepTitle style={{ marginTop: 25 }}>
								What is minimum billable duration for this rate?
							</StepTitle>
							<InfoBox
								style={{ marginBottom: 16 }}
								text={
									"This feature allows hourly bookings to be charged by the nearest factor."
								}
							/>
							<Dropdown
								options={options}
								value={
									options.find(
										(value) => value.value === values.hourlyBillableDuration
									) || defaultHourlyBillableDuration
								}
								onChange={({ value }) => {
									setFieldValue("hourlyBillableDuration", value);
								}}
							/>
						</div>
					)}

					{values.durationType === "Days" && (
						<div style={{ marginTop: "16px" }}>
							<Toggle
								label="Varied Rate"
								onChange={(value) => {
									if (value) {
										dayLabels.forEach((dailyLabel, dayIndex) => {
											setFieldValue(
												`dailyFees[${dayIndex}]`,
												values.monthlyFee
											);
										});
									}
									setFieldValue("variedDailyRate", value);
								}}
								checked={values.variedDailyRate}
							/>
							{values.variedDailyRate &&
								dayLabels.map((dayLabel, dayIndex) => (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											gap: 36,
											marginTop: "12px",
										}}
										key={dayLabel}
									>
										<div style={{ width: "100px" }}>{dayLabel}</div>
										<InputWithIcon
											icon="DollarSign"
											type="number"
											name="dailyRate"
											align="left"
											value={values.dailyFees[dayIndex]}
											useLabel={inputLabel}
											onFormat={(value) =>
												setFieldValue(`dailyFees[${dayIndex}]`, value)
											}
										/>
									</div>
								))}
						</div>
					)}
				</div>
			);
		},
		footer: () =>
			_footer(
				values,
				previous,
				goTo,
				next,
				false,
				() => errors.monthlyFee,
				keyStrokeHandler
			),
	};
};

export const bookInAdvanceStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => {
	let nextStep;
	if (values.durationType === "Hours") {
		nextStep = "durationStep";
	} else if (values.durationType === "Event") {
		nextStep = "eventSeriesSelectionStep";
	} else {
		nextStep = "minimumDurationMonths";
	}

	return {
		id: "bookInAdvance",
		label: "Book In Advance",
		render: () => (
			<BookInAdvanceStep
				bookInAdvanceDuration={values.bookInAdvanceDuration}
				bookInAdvanceUnitType={values.bookInAdvanceUnitType}
				errors={errors}
				setFieldValue={setFieldValue}
			/>
		),
		footer: () =>
			_footer(
				values,
				previous,
				goTo,
				nextStep,
				false,
				() =>
					errors.bookInAdvanceDuration ||
					errors.bookInAdvanceUnitType ||
					(values.bookInAdvanceDuration && !values.bookInAdvanceUnitType) ||
					(!values.bookInAdvanceDuration && values.bookInAdvanceUnitType),
				keyStrokeHandler
			),
	};
};

export const eventSeriesSelectionStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => {
	return {
		id: "eventSeriesSelectionStep",
		label: "Event Series",
		render: () => {
			return (
				<React.Fragment>
					{values.conflictRateErrorMessage && (
						<AlertMessage
							text={values.conflictRateErrorMessage}
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
					<StepTitle>Which event series will this rate apply to?</StepTitle>
					<Dropdown
						isMulti={false}
						options={wizardProps?.eventSeries?.map((series) => ({
							value: series.EventSeriesID,
							label: series.Name,
						}))}
						value={values.selectedEventSeries}
						onChange={(selected) => {
							setFieldValue("selectedEventSeries", selected);
						}}
						placeholder="Select event series"
					/>
				</React.Fragment>
			);
		},
		footer: () =>
			_footer(
				values,
				previous,
				goTo,
				"groups",
				false,
				() => values.selectedEventSeries.length === 0,
				keyStrokeHandler
			),
	};
};

export const minimumDurationMonths = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => {
	return {
		id: "minimumDurationMonths",
		label: "Minimum Time",
		render: () => (
			<MinimumDurationStep
				durationType={values.durationType}
				setFieldValue={setFieldValue}
				errors={errors}
				minimumDurationAmount={values.minimumDurationAmount}
				maximumDurationAmount={values.maximumDurationAmount}
			/>
		),
		footer: () =>
			_footer(
				values,
				previous,
				goTo,
				values.durationType === "Days"
					? "availableDates"
					: "unitToPayInAdvance",
				false,
				() => errors.minimumDurationMonths || !values.minimumDurationAmount,
				keyStrokeHandler
			),
	};
};

export const durationStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => {
	return {
		id: "durationStep",
		label: "Duration",
		render: () => {
			return (
				<DurationStep
					rateType={helper.getUnitLabel(values.durationType, "period", "lower")}
					setFieldValue={setFieldValue}
					minimumDurationAmount={values.minimumDurationAmount}
					maximumDurationAmount={values.maximumDurationAmount}
					durationType={values.durationType}
					maximumDurationType={values.maximumDurationType}
					hourlyBillableDuration={values.hourlyBillableDuration}
					errors={errors.exceedsMaxDuration || null}
				/>
			);
		},
		footer: () =>
			_footer(
				values,
				previous,
				goTo,
				"availableDates",
				false,
				() => errors.exceedsMaxDuration || !values.minimumDurationAmount,
				keyStrokeHandler
			),
	};
};

export const unitToPayInAdvance = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => ({
	id: "unitToPayInAdvance",
	label: "Advance Payment",
	render: () => {
		return (
			<div>
				<StepTitle>
					How long would you like the user to pay this booking in advance?
				</StepTitle>
				<EditableInputField
					type="number"
					name={"unitToPayInAdvance"}
					onChange={(event) =>
						setFieldValue("unitToPayInAdvance", event.target.value)
					}
					value={values.unitToPayInAdvance}
					useLabel="Minimum"
					rightUnit="Months"
					error={
						errors.unitToPayInAdvance
							? errors.unitToPayInAdvance
							: values.unitToPayInAdvance > values.minimumDurationAmount
							? "The Months to pay in advance cannot exceed the minimum duration."
							: null
					}
				/>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"earlyCancellationFee",
			false,
			() =>
				errors.unitToPayInAdvance ||
				!values.unitToPayInAdvance ||
				values.unitToPayInAdvance > values.minimumDurationAmount,
			keyStrokeHandler
		),
});

export const earlyCancellationFee = ({
	touched,
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => ({
	id: "earlyCancellationFee",
	label: "Cancellation",
	render: () => {
		return (
			<div>
				<StepTitle>What is the cancellation fee for this rate?</StepTitle>

				<InputWithIcon
					icon="DollarSign"
					type="number"
					name="earlyCancellationFee"
					align="left"
					value={values.earlyCancellationFee}
					useLabel="Fee per Term"
					onFormat={(value) => setFieldValue("earlyCancellationFee", value)}
				/>
				<FieldError
					showError={
						touched.earlyCancellationFee && errors.earlyCancellationFee
					}
					errorText={errors.earlyCancellationFee}
				/>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"proRataDay",
			false,
			() => errors.earlyCancellationFee,
			keyStrokeHandler
		),
});

export const proRataDay = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => ({
	id: "proRataDay",
	label: "Pro-rata Day",
	render: () => {
		let dayOptions = [{ value: null, label: "No pro-rata" }];

		while (dayOptions.length <= 28) {
			dayOptions.push({
				value: dayOptions.length,
				label: `Day ${dayOptions.length}`,
			});
		}

		return (
			<div>
				<StepTitle>Please select the pro-rata day</StepTitle>
				<Dropdown
					options={dayOptions}
					value={values.proRataDay}
					onChange={(option) => setFieldValue("proRataDay", option)}
				/>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"availableDates",
			false,
			() => errors.proRataDay || !values.proRataDay,
			keyStrokeHandler
		),
});

export const availableDates = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => ({
	id: "availableDates",
	label: "Available Dates",
	render: () => {
		return (
			<div>
				<StepTitle>
					Please select the available dates for this{" "}
					{helper.getUnitLabel(values.durationType, "period", "lower")} rate
				</StepTitle>
				<h3>Available from:</h3>
				<DatePicker
					options={{ pickerMode: "datePicker", showClearButton: true }}
					value={values.startDate}
					onChange={([date]) => {
						setFieldValue("startDate", date);

						if (
							date &&
							values.endDate &&
							moment(values.endDate).isBefore(moment(date))
						) {
							setFieldValue("endDate", null);
						}
					}}
					whenCleared={() => setFieldValue("startDate", null)}
				/>
				<h3>Available to:</h3>
				<DatePicker
					options={{ pickerMode: "datePicker", showClearButton: true }}
					value={values.endDate}
					onChange={([date]) => {
						setFieldValue("endDate", date);

						if (
							values.startDate &&
							date &&
							moment(date).isBefore(moment(values.startDate))
						) {
							setFieldValue("startDate", null);
						}
					}}
					whenCleared={() => setFieldValue("endDate", null)}
				/>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"groups",
			false,
			() => errors.minimumDurationMonths,
			keyStrokeHandler
		),
});

export const groups = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "groups",
	label: "Groups",
	render: () => (
		<GroupStep
			organizationAccessGroups={wizardProps.organizationAccessGroups}
			selectGroups={values.organizationAccessGroups}
			setFieldValue={setFieldValue}
			durationType={values.durationType}
			errors={errors.minimumDurationMonths}
		/>
	),
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"leaseParks",
			false,
			() =>
				!values.organizationAccessGroups ||
				values.organizationAccessGroups.length === 0 ||
				errors.minimumDurationMonths,
			keyStrokeHandler
		),
});

export const leaseParks = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "leaseParks",
	label: "Spaces",
	render: () => {
		let unfilteredLeaseParks = wizardProps.leaseParks;

		// If we have selected event series, we need to filter the spaces to only show those that are part of the selected event series
		if (
			values.durationType === DURATION_TYPES.EVENT &&
			!isNil(values.selectedEventSeries)
		) {
			//Get all the event series ids that have been selected on an earlier step of the wizard.
			let selectedEventSeriesId = values.selectedEventSeries.value;
			if (Array.isArray(values.selectedEventSeries)) {
				selectedEventSeriesId = values.selectedEventSeries[0].value;
			}
			//Find the full data for the event series that have been selected
			let selectedEventSeries = wizardProps.eventSeries.find(
				(eventSeries) => eventSeries.EventSeriesID === selectedEventSeriesId
			);

			unfilteredLeaseParks = selectedEventSeries.Spaces.map((space) => {
				return {
					...space,
					sites: [space.Site],
				};
			});
		}

		//Get all options as dropdown options except those which are already in use
		let options = unfilteredLeaseParks.reduce((arr, leasePark) => {
			const siteLabel = leasePark?.sites
				?.map((lpSite) => lpSite?.Name)
				?.join(", ");

			arr.push({
				leaseParkName: leasePark.Name,
				value: leasePark.LeaseParkID,
				label: `${leasePark.Name} ${siteLabel ? `(${siteLabel})` : ""}`,
			});
			return arr;
		}, []);

		options = sortBy(options, (option) => option.label.toLowerCase());

		return (
			<div>
				<StepTitle>
					Please choose the space blocks that apply to this{" "}
					{helper.getUnitLabel(values.durationType, "period", "lower")} booking
				</StepTitle>
				<div style={{ marginBottom: 16 }}>
					Please note that you can only assign rates with different minimum
					durations to the same parking space
				</div>
				<Dropdown
					isMulti={true}
					options={options}
					value={values.leaseParks}
					onChange={(value) => {
						setFieldValue("leaseParks", value);
					}}
				/>
				<FieldError
					showError={errors.leaseParks}
					errorText={errors.leaseParks}
				/>
				<FieldError
					showError={errors.minimumDurationMonths}
					errorText={errors.minimumDurationMonths}
				/>
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			() => {
				setFieldValue("leaseParks", []);
				previous();
			},
			goTo,
			values.durationType === DURATION_TYPES.MONTHS ? "sublease" : "summary",
			false,
			() =>
				!values.leaseParks ||
				values.leaseParks.length < 1 ||
				errors.minimumDurationMonths,
			keyStrokeHandler
		),
});

export const sublease = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
	wizardProps,
}) => ({
	id: "sublease",
	label: "Sublet",
	render: () => {
		const availableRates = wizardProps.leaseRates
			.filter(
				(lr) =>
					lr.DurationType === DURATION_TYPES.DAYS &&
					((lr.MinimumDurationMonths === 1 && lr.MaximumDurationAmount === 1) ||
						lr.NonConsecutiveBooking)
			)
			.map((lr) => ({
				label: lr.Name,
				value: [lr.LeaseRateID], // change handeling when support for multiple sublet rates
			}));

		return (
			<div>
				<StepTitle>Is this booking rate able to be sublet?</StepTitle>
				<MultiChoiceButton
					selected={!values.supportSubleases}
					onClick={() => {
						setFieldValue("supportSubleases", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.supportSubleases}
					onClick={() => {
						setFieldValue("supportSubleases", true);
					}}
				>
					Yes
				</MultiChoiceButton>
				{values.supportSubleases && (
					<>
						<StepTitle>
							Which daily rate should be selected for sublet?
						</StepTitle>
						{availableRates.length ? (
							<Dropdown
								options={availableRates}
								value={availableRates.find(
									(r) => r.value[0] === values.subletRateIds[0]
								)}
								onChange={(option) =>
									setFieldValue("subletRateIds", option.value)
								}
							/>
						) : (
							<div>
								No daily rate available. Please create a daily rate in the
								Booking Rates page to assign for sublet
							</div>
						)}
					</>
				)}
			</div>
		);
	},
	footer: () =>
		_footer(
			values,
			previous,
			goTo,
			"summary",
			false,
			() =>
				errors.sublease ||
				(values.supportSubleases && !values.subletRateIds.length),
			keyStrokeHandler
		),
});

export const summary = ({
	handleSubmit,
	isSubmitting,
	goTo,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => {
	function _edit(step) {
		setFieldValue("editFromSummary", true);
		goTo(step);
	}

	//get space and site name display
	let spaceSites = [];
	if (values.leaseParks && values.leaseParks.length) {
		const spaceIds = values.leaseParks.map((d) => d.value);
		wizardProps.leaseParks.forEach((leasePark) => {
			if (spaceIds.includes(leasePark.LeaseParkID)) {
				spaceSites.push(
					`${leasePark.Name} (${leasePark.sites
						.map((site) => site.Name)
						.join(", ")})`
				);
			}
		});
	}

	let durationSpecificItems = [];
	if (values.durationType === DURATION_TYPES.MONTHS) {
		durationSpecificItems = [
			{
				title: "Months to pay in advance",
				value: values.unitToPayInAdvance,
				key: "unitToPayInAdvance",
				edit: () => _edit("unitToPayInAdvance"),
			},
			{
				title: "Cancellation Fee",
				value: moneyFormat(values.earlyCancellationFee),
				key: "earlyCancellationFee",
				edit: () => _edit("earlyCancellationFee"),
			},
			{
				title: "Pro Rata day",
				value: values.proRataDay.label,
				key: "proRataDay",
				edit: () => _edit("proRataDay"),
			},
		];
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => {
			let _availableDates = "";
			if (!values.startDate & !values.endDate) {
				_availableDates = "Any time";
			} else {
				let startDate = values.startDate
					? `From ${moment(values.startDate).format("DD MMM YYYY")}`
					: "";
				let endDate = values.endDate
					? `To ${moment(values.endDate).format("DD MMM YYYY")}`
					: "";

				_availableDates = `${startDate} ${endDate}`;
			}

			let durationSummary = [];
			if (values.durationType === DURATION_TYPES.EVENT) {
				let eventSeriesSelected =
					values.selectedEventSeries.map((event) => event.label).join("\n") +
					"\n";

				durationSummary = [
					{
						title: "Event Series",
						value: eventSeriesSelected,
						key: "EventSummary",
						edit: () => _edit("eventSeriesSelectionStep"),
					},
				];
			} else {
				durationSummary = [
					{
						title: "Book In Advance",
						value: values.bookInAdvanceDuration
							? `${values.bookInAdvanceDuration} ${
									values.bookInAdvanceDuration > 1
										? helper.getUnitLabel(
												values.bookInAdvanceUnitType,
												"plural",
												"lower"
										  )
										: helper.getUnitLabel(
												values.bookInAdvanceUnitType,
												"single",
												"lower"
										  )
							  }`
							: "No limit",
						key: "bookInAdvance",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("bookInAdvance");
						},
					},
					{
						title: "Duration",
						value:
							helper.summaryDurationString(
								values.minimumDurationAmount,
								values.durationType,
								"Minimum",
								values.hourlyBillableDuration === 30
							) +
							", " +
							helper.summaryDurationString(
								values.maximumDurationAmount,
								values.maximumDurationType,
								"Maximum",
								values.hourlyBillableDuration === 30
							),
						key: "minimumDurationMonths",
						edit: () =>
							values.durationType === "Hours"
								? _edit("durationStep")
								: _edit("minimumDurationMonths"),
					},
					...durationSpecificItems,
					{
						title: "Available Dates",
						value: _availableDates,
						key: "availableDates",
						edit: () => _edit("availableDates"),
					},
				];
			}

			const items = [
				{
					title: "Duration",
					value: `${values.durationType}${
						values.nonConsecutiveBooking ? " (Non-Consecutive)" : ""
					}`,
					key: "durationType",
				},
				{
					title: "Name",
					value: values.name,
					key: "name",
					edit: () => _edit("name"),
				},
				{
					title: "Fee",
					value: values.variedDailyRate
						? dailyFeeRange(values.dailyFees)
						: values.monthlyFee,
					key: "monthlyFee",
					edit: () => _edit("monthlyFee"),
				},
				...durationSummary,

				{
					title: "Groups",
					value:
						values.organizationAccessGroups &&
						values.organizationAccessGroups.length > 0
							? values.organizationAccessGroups
									.map((item) => item.label)
									.join(", ")
							: "Public",
					key: "groups",
					edit: () => _edit("groups"),
				},
				{
					title: "Spaces",
					value: spaceSites.length ? spaceSites.join(", ") : "",
					key: "leaseParks",
					edit: () => _edit("leaseParks"),
				},
			];

			if (values.durationType === DURATION_TYPES.MONTHS) {
				items.push({
					title: "Sublet",
					value: values.supportSubleases ? "Yes" : "No",
					key: "sublease",
					edit: () => _edit("sublease"),
				});
			}

			return (
				<div>
					<StepTitle>Summary</StepTitle>

					{errors.minimumDurationMonths && (
						<AlertMessage
							text={errors.minimumDurationMonths}
							borderColor="red"
							backgroundColor="red"
							textColor="white"
							top="30px"
						/>
					)}

					<SummaryTable items={items} />
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				rightItems={[
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={Object.keys(errors).length > 0 || isSubmitting}
						keyStrokeHandler={keyStrokeHandler}
					>
						{wizardProps.mode === "update" ? "Update" : "Create"} Booking Rate
					</Button>,
				]}
			/>
		),
	};
};

export const remove = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>Are you sure you want to remove {values.name}?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove
				</Button>,
			]}
		/>
	),
});
