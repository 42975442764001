import gql from "graphql-tag";
import { useQueryData, useMutateData, useLazyQueryData } from "../../../hooks";

export const useGetAllEventSeries = (isActive) => {
	const { data, isLoading, isError, refetch } = useQueryData(
		gql`
			query GetAllEventSeries($isActive: Boolean) {
				getAllEventSeries(isActive: $isActive) {
					EventSeriesID
					OrganizationID
					Name
					ResetTime
					BrandID
					Sites {
						SiteID
					}
					Spaces {
						LeaseParkID
						Name
						Site {
							Name
							SiteID
						}
					}
				}
			}
		`,
		{ isActive }
	);

	const eventSeries = data ? data.getAllEventSeries : null;

	return { eventSeries, isLoading, isError, refetch };
};

export const useCheckForEventSeriesRateConflicts = (
	events,
	groupIds,
	siteIds,
	rateIds
) => {
	return useLazyQueryData(
		gql`
			query CheckForEventSeriesRateConflicts(
				$events: [EventInput!]
				$groupIds: [Int]
				$siteIds: [Int!]
				$rateIds: [Int]
			) {
				checkForEventSeriesRateConflicts(
					events: $events
					groupIds: $groupIds
					siteIds: $siteIds
					rateIds: $rateIds
				) {
					message
				}
			}
		`,
		{ events, groupIds, siteIds, rateIds }
	);
};

export const useGetSitesByIds = (siteIds) => {
	const skip =
		!Array.isArray(siteIds) ||
		siteIds.length === 0 ||
		siteIds.some((id) => isNaN(id));

	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetSitesByIds($siteIds: [Int]!) {
				getSitesByIds(siteIds: $siteIds) {
					SiteID
					Name
					EventSeriesRates {
						RateID
						Name
						RateClass
						RootRateID
					}
				}
			}
		`,
		{
			siteIds,
		},
		skip
	);

	const sites = data ? data.getSitesByIds : null;

	return { sites, isLoading, isError };
};

export const useGetEventSeriesByRateId = (rateId) => {
	const skip = !rateId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query getSeriesByRateId($rateId: ID!) {
				getSeriesByRateId(rateId: $rateId) {
					EventSeriesID
					Name
				}
			}
		`,
		{
			rateId,
		},
		skip
	);

	const eventSeries = data ? data.getSeriesByRateId : null;

	return { eventSeries, isLoading, isError };
};

export const useGetAllRates = () => {
	const { data, isLoading, isError } = useQueryData(
		gql`
			query {
				getRates {
					RateID
					SiteID
					Name
				}
			}
		`
	);

	const rates = data ? data.getRates : null;

	return { rates, isLoading, isError };
};

export const useGetAllEventsBySeries = (seriesId, isActive) => {
	const skip = !seriesId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetAllEventsBySeries($seriesId: ID!, $isActive: Boolean) {
				getAllEventsBySeries(seriesId: $seriesId, isActive: $isActive) {
					EventID
					EventSeriesID
					Name
					StartTimestamp
					EndTimestamp
				}
			}
		`,
		{
			seriesId,
			isActive,
		},
		skip
	);

	return { data, isLoading, isError };
};

export const useGetSeriesById = (seriesId) => {
	const skip = !seriesId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetSeriesById($seriesId: ID!) {
				getSeriesById(seriesId: $seriesId) {
					EventSeriesID
					Name
					ResetTime
					Events {
						EndTimestamp
						EventID
						EventSeriesID
						Name
						StartTimestamp
					}
					Groups {
						Name
						OrganizationAccessGroupID
						Sites {
							Name
							SiteID
							Timezone
							LeaseParks {
								Name
								Spaces
								LeaseParkID
							}
						}
					}
					Sites {
						Name
						SiteID
						Timezone
						LeaseParks {
							Name
							Spaces
							LeaseParkID
						}
					}
					Spaces {
						Name
						LeaseParkID
					}
					Brand {
						BrandID
						CompanyName
						IconLogo
						MainColor
						SecondaryColor
					}
					EventSeriesRates {
						Name
						RateID
						SiteID
					}
				}
			}
		`,
		{
			seriesId,
		},
		skip
	);

	const seriesData = data ? data.getSeriesById : {};

	return { seriesData, isLoading, isError };
};

export const useGetEventById = (eventId) => {
	const skip = !eventId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetEventById($eventId: ID!) {
				getEventById(eventId: $eventId) {
					EventSeries {
						Name
						EventSeriesID
					}
					Brand {
						SecondaryColor
						IconLogo
						MainColor
					}
					Name
					EndTimestamp
					StartTimestamp
					EventID
					Sites {
						Name
						Timezone
					}
					Groups {
						Name
					}
				}
			}
		`,
		{
			eventId,
		},
		skip
	);

	const eventData = data ? data.getEventById : null;
	return { eventData, isLoading, isError };
};

export const useSearchEvents = (
	searchQuery,
	siteIds,
	seriesIds,
	startTimestamp,
	endTimestamp,
	paginationData
) => {
	const { data, isLoading, isError, refetch } = useQueryData(
		gql`
			query searchEvents(
				$searchQuery: String
				$siteIds: [Int!]
				$seriesIds: [Int!]
				$startTimestamp: Timestamp
				$endTimestamp: Timestamp
				$paginationData: Page!
			) {
				searchEvents(
					searchQuery: $searchQuery
					siteIds: $siteIds
					seriesIds: $seriesIds
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
					paginationData: $paginationData
				) {
					connection
					trueLength
					pageIndex
					Events {
						StartTimestamp
						EndTimestamp
						Name
						EventID
						Brand {
							IconLogo
						}
						EventSeries {
							EventSeriesID
							Name
						}
						Groups {
							Name
						}
						Sites {
							Timezone
							Name
						}
					}
				}
			}
		`,
		{
			searchQuery,
			siteIds,
			seriesIds,
			startTimestamp,
			endTimestamp,
			paginationData,
		}
	);

	const returnedData = data && data.searchEvents ? data.searchEvents : {};
	const {
		Events: eventsData,
		connection,
		pageIndex,
		trueLength,
	} = returnedData;

	return {
		eventsData,
		connection,
		pageIndex,
		trueLength,
		isLoading,
		isError,
		refetch,
	};
};

export const useSearchEventPasses = (
	searchQuery,
	siteIds,
	seriesIds,
	startTimestamp,
	endTimestamp,
	paginationData
) => {
	const { data, isLoading, isError, refetch } = useQueryData(
		gql`
			query searchEventPasses(
				$searchQuery: String
				$siteIds: [Int!]
				$seriesIds: [Int!]
				$startTimestamp: Timestamp
				$endTimestamp: Timestamp
				$paginationData: Page!
			) {
				searchEventPasses(
					searchQuery: $searchQuery
					siteIds: $siteIds
					seriesIds: $seriesIds
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
					paginationData: $paginationData
				) {
					connection
					trueLength
					pageIndex
					EventPasses {
						EventPassID
						UserID
						UserInvitationID
						NumberplateID
						EnteredAt
						Email
						Numberplate
						Description
						StartTimestamp
						EndTimestamp
						Event {
							EventID
							Name
						}
						Group {
							OrganizationAccessGroupID
							Name
						}
						Sites {
							Name
							Timezone
						}
						CheckInHistory {
							CheckInMethod
							Numberplate {
								NumberplateID
								Numberplate
							}
							CreatedOn
						}
					}
				}
			}
		`,
		{
			searchQuery,
			siteIds,
			seriesIds,
			startTimestamp,
			endTimestamp,
			paginationData,
		}
	);

	const {
		EventPasses: eventPassesData,
		connection,
		pageIndex,
		trueLength,
	} = data?.searchEventPasses ?? {};

	return {
		eventPassesData,
		connection,
		pageIndex,
		trueLength,
		isLoading,
		isError,
		refetch,
	};
};

export const useGetLeaseParkBySeriesId = (seriesId) => {
	const skip = !seriesId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetLeaseParkBySeriesId($seriesId: ID!) {
				getLeaseParkBySeriesId(seriesId: $seriesId) {
					LeaseParkID
				}
			}
		`,
		{
			seriesId,
		},
		skip
	);

	return { data, isLoading, isError };
};

export const useGetGroupsForEventSeries = (seriesId) => {
	const skip = !seriesId;

	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetGroupsForEventSeries($seriesId: ID!) {
				getGroupsForEventSeries(seriesId: $seriesId) {
					OrganizationAccessGroupID
					Name
					AllowWhitelistedPlates
				}
			}
		`,
		{
			seriesId,
		},
		skip
	);

	return { data, isLoading, isError };
};

export const useGetLeaseParksForSiteMaps = (siteIds) => {
	const skip = !siteIds;
	const {
		data,
		loading: parksIsLoading,
		isError,
		refetch: refetchParks,
	} = useQueryData(
		gql`
			query GetAllLeaseParksForSiteIds($siteIds: [Int!]) {
				getAllLeaseParksForSiteIds(siteIds: $siteIds) {
					LeaseParkID
					Name
				}
			}
		`,
		{ siteIds: siteIds },
		skip
	);

	const leaseParks = data ? data.getAllLeaseParksForSiteIds : null;

	return { leaseParks, parksIsLoading, isError, refetchParks };
};

export const useUpsertEventSeriesAndEvents = () => {
	return useMutateData(
		gql`
			mutation UpsertEventSeriesAndEvents(
				$name: String!
				$brandId: Int!
				$events: [EventInput]!
				$groupIds: [Int]!
				$siteIds: [Int]!
				$leaseParkIds: [Int]!
				$rateIds: [Int]!
				$eventSeriesId: Int
				$resetTime: Int
			) {
				upsertEventSeriesAndEvents(
					name: $name
					brandId: $brandId
					events: $events
					groupIds: $groupIds
					siteIds: $siteIds
					leaseParkIds: $leaseParkIds
					rateIds: $rateIds
					eventSeriesId: $eventSeriesId
					resetTime: $resetTime
				) {
					EventSeriesID
				}
			}
		`
	);
};

export const useGetEventsByOrganizationId = (organizationId) => {
	return useQueryData(
		gql`
			query ($organizationId: Int!) {
				getEventsByOrganizationId(organizationId: $organizationId) {
					EventID
					Name
					StartTimestamp
					EndTimestamp
					Sites {
						SiteID
					}
				}
			}
		`,
		{
			organizationId: organizationId,
		},
		!organizationId
	);
};
