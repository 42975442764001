import {
	DAYS,
	DAYS_OR_EARLIER,
	DEFAULT_DATES,
	DEFAULT_SPECIAL_CONDITION,
	EXIT_CONDITIONS,
	SPECIAL_CONDITIONS,
} from "../../../../helpers/organization";
import AlertMessage from "../../../layout/AlertMessage";
import Button from "../../../layout/Button";
import DatePicker from "../../../layout/DatePicker";
import Dropdown from "../../../layout/Dropdown";
import InputWithIcon from "../../../layout/InputWithIcon";
import React from "react";
import Repeater from "../../../layout/Repeater";
import { StepTitle } from "../../WizardLayout";
import TimeRangePicker from "../../../layout/TimeRangePicker";
import { colours } from "../../../../styles";
import moment from "moment";
import styled from "styled-components";

const Separator = styled.div`
	display: inline-block;
	margin: 18px 0 0;
	padding: 0 14px 0 4px;
`;
const Wrapper = styled.div`
	display: flex;
	margin-bottom: 10px;
	label {
		color: ${colours.midGrey};
		font-size: 18px;
		font-weight: 400;
		pointer-events: none;
		transform: scale(1);
		transform-origin: left top;
		transition: 0.2s ease;
		margin-bottom: 10px;
	}
`;

/**
 * Gets an array of three-character Day codes in chronological order for a given rate.
 *
 * This factors in week wrapping, so a rate which 'starts' on Thursday and ends on Tuesday will return
 * "Thu, Fri, Sat, Sun, Mon, Tue".
 */
const dayValues = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default ({ values, setFieldValue, goTo }) => {
	return {
		id: "advanced",
		summary: () => {
			const isExitBetween =
				values.exitCondition && values.exitCondition.value === "exitBetween";
			const from = dayValues.indexOf(values.enterOnDay.value);
			const to = dayValues.indexOf(
				isExitBetween
					? values.exitOnDay && values.exitOnDay.value
					: values.exitOnOrBefore && values.exitOnOrBefore.value
			);
			let selected = [];
			if (from > to) {
				selected = [
					...dayValues.slice(from, dayValues.length),
					...dayValues.slice(0, to + 1),
				].join(", ");
			} else {
				selected = dayValues.slice(from, to + 1).join(", ");
			}
			return [
				{
					id: "days",
					label: "Days of the week",
					value: selected,
					edit: () => {
						setFieldValue("editFromSummary", true);
						setFieldValue("configuration", "advanced");
						goTo("configuration");
					},
				},
				{
					id: "advanced",
					label: "Hours",
					value: (
						<React.Fragment>
							<span>
								Enter Between: {moment(values.entryLaterThan).format("HH:mm")}{" "}
								and {moment(values.entryEarlierThan).format("HH:mm")}
							</span>
							<br />
							<span>
								{values.exitCondition.label}:{" "}
								{values.exitCondition.value === "exitBetween" &&
									`${moment(values.exitLaterThan).format("HH:mm")} and `}
								{moment(values.exitEarlierThan).format("HH:mm")}
							</span>
						</React.Fragment>
					),
					edit: () => {
						setFieldValue("editFromSummary", true);
						setFieldValue("configuration", "advanced");
						goTo("configuration");
					},
				},
				{
					id: "special",
					label: "Special Conditions",
					value: values.specialConditionItems.length,
					edit: () => {
						setFieldValue("editFromSummary", true);
						setFieldValue("configuration", "advanced");
						goTo("configuration");
					},
				},
			];
		},
		render: () => {
			const isExitBetween =
				values.exitCondition && values.exitCondition.value === "exitBetween";
			const days = isExitBetween ? DAYS : DAYS_OR_EARLIER;
			const selectedDay = isExitBetween
				? values.exitOnDay
				: values.exitOnOrBefore;
			const exitOn = isExitBetween ? "exitOnDay" : "exitOnOrBefore";
			return (
				<React.Fragment>
					{values.conflictRateErrorMessage &&
						values.conflictRateErrorMessage.map((message) => (
							<AlertMessage
								key={message}
								text={message}
								borderColor="red"
								backgroundColor="red"
								textColor="white"
							/>
						))}
					<StepTitle>
						When will this advanced / complex rate be available?
					</StepTitle>
					<Wrapper>
						<Separator style={{ width: "168px" }}>Enter between</Separator>
						<div style={{ marginRight: "-10px" }}>
							<TimeRangePicker
								canEndAt24Hours
								dash="and"
								startTime={values.entryLaterThan}
								endTime={values.entryEarlierThan}
								noLeftPadding={true}
								onChange={({ from, to }) => {
									setFieldValue("entryLaterThan", from);
									setFieldValue("entryEarlierThan", to);
								}}
							/>
						</div>
						<Separator>on</Separator>
						<div style={{ width: "20%", marginTop: "9px" }}>
							<Dropdown
								options={days}
								value={values.enterOnDay}
								onChange={(day) => {
									setFieldValue("enterOnDay", day);
								}}
							/>
						</div>
					</Wrapper>
					<Wrapper>
						<div style={{ width: "25%", marginTop: "10px" }}>
							<Dropdown
								options={EXIT_CONDITIONS}
								value={values.exitCondition}
								onChange={(condition) => {
									setFieldValue(
										"exitLaterThan",
										DEFAULT_DATES.Complex.ExitLaterThan
									);
									setFieldValue(
										"exitEarlierThan",
										DEFAULT_DATES.Complex.ExitEarlierThan
									);
									const _isExitBetween = condition.value === "exitBetween";
									const _exitOn = _isExitBetween
										? "exitOnDay"
										: "exitOnOrBefore";
									const notExitOn =
										_exitOn === "exitOnDay" ? "exitOnOrBefore" : "exitOnDay";
									const selected = (
										_isExitBetween ? DAYS : DAYS_OR_EARLIER
									).find((day) =>
										values[_exitOn || notExitOn]
											? day.value === values[_exitOn || notExitOn].value
											: day.value === "Fri"
									);

									setFieldValue(_exitOn, selected);
									setFieldValue(notExitOn, null);

									setFieldValue("exitCondition", condition);
								}}
							/>
						</div>

						{isExitBetween ? (
							<div style={{ marginRight: "-10px" }}>
								<TimeRangePicker
									canEndAt24Hours
									dash="and"
									startTime={values.exitLaterThan}
									endTime={values.exitEarlierThan}
									noLeftPadding={true}
									onChange={({ from, to }) => {
										setFieldValue("exitLaterThan", from);
										setFieldValue("exitEarlierThan", to);
									}}
								/>
							</div>
						) : (
							<div
								style={{
									marginTop: "10px",
									marginLeft: "8px",
									marginRight: "15px",
								}}
							>
								<DatePicker
									options={{
										pickerMode: "timePicker",
										time_24hr: true,
									}}
									value={values.exitEarlierThan}
									onChange={([exitEarlierThan]) => {
										setFieldValue("exitEarlierThan", exitEarlierThan);
									}}
								/>
							</div>
						)}

						<Separator style={isExitBetween ? null : { marginRight: "4px" }}>
							on
						</Separator>
						<div
							style={{
								width: isExitBetween ? "20%" : "46%",
								marginTop: "8px",
							}}
						>
							<Dropdown
								options={days}
								value={selectedDay}
								onChange={(day) => {
									setFieldValue(
										exitOn,
										(isExitBetween ? DAYS : DAYS_OR_EARLIER).find(
											(d) => d.value === day.value
										)
									);
								}}
							/>
						</div>
					</Wrapper>
					{values.specialConditionsEnabled && (
						<Wrapper>
							<div style={{ width: "100%" }}>
								<Repeater
									items={values.specialConditionItems}
									actionRules={[
										{
											minus: (items) => items.length > 1,
											plus: (items) => items.length === 1,
										},
										{ minus: true, plus: false },
									]}
									add={() => {
										const items = [...values.specialConditionItems];
										const conditions = SPECIAL_CONDITIONS.filter(
											(c) => c.value !== items[0].condition.value
										);
										items.push({
											minutes: 0,
											conditions,
											condition: conditions[0],
										});
										items[0].conditions = SPECIAL_CONDITIONS.filter(
											(c) => c.value === items[0].condition.value
										);
										setFieldValue("specialConditionItems", items);
									}}
									subtract={(index) => {
										const items = [...values.specialConditionItems];
										items.splice(index, index + 1);
										items[0].conditions = SPECIAL_CONDITIONS;
										setFieldValue("specialConditionItems", items);
									}}
									template={(item, index) => (
										<React.Fragment>
											<div style={{ width: "45.5%", marginRight: "8px" }}>
												<Dropdown
													options={item.conditions}
													value={item.condition}
													onChange={(_item) => {
														const items = [...values.specialConditionItems];
														items[index].condition = _item;
														setFieldValue("specialConditionItems", items);
													}}
												/>
											</div>

											<div style={{ marginRight: "8px" }}>
												<InputWithIcon
													value={item.minutes}
													onFormat={(value) => {
														const items = [...values.specialConditionItems];
														items[index].minutes = value;
														setFieldValue("specialConditionItems", items);
													}}
													width="177px"
													icon="Clock"
													align="left"
												/>
											</div>
											<Separator style={{ marginTop: "8px" }}>
												Minutes
											</Separator>
										</React.Fragment>
									)}
								/>
							</div>
						</Wrapper>
					)}
					<Button
						style={{ width: "100%" }}
						key="addSpecialConditions"
						color={values.specialConditionsEnabled ? "lightGrey" : "green"}
						onClick={() => {
							if (values.specialConditionsEnabled) {
								setFieldValue("specialConditionItems", []);
							} else {
								setFieldValue("specialConditionItems", [
									{
										minutes: 0,
										condition: DEFAULT_SPECIAL_CONDITION,
										conditions: SPECIAL_CONDITIONS,
									},
								]);
							}

							setFieldValue(
								"specialConditionsEnabled",
								!values.specialConditionsEnabled
							);
						}}
					>
						{values.specialConditionsEnabled
							? "Disable Special Conditions"
							: "Enable Special Conditions"}
					</Button>
				</React.Fragment>
			);
		},
		isValid: values.startTimestamp < values.endTimestamp,
	};
};
