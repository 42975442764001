import Alert from "react-s-alert";
import Dropdown from "../../../layout/Dropdown";
import Input from "../../../layout/Input";
import InputWithIcon from "../../../layout/InputWithIcon";
import React from "react";
import Repeater from "../../../layout/Repeater";
import { StepTitle } from "../../WizardLayout";
import Toggle from "../../../layout/Toggle";
import styled from "styled-components";

const Separator = styled.div`
	display: inline-block;
	margin: 8px 4px 0;
	padding: 0 8px 0 8px;
`;

const Wrapper = styled.div`
	display: flex;
`;

const invalid = function (cachedVariedRates) {
	let infiniteCount = 0;

	const variedRates = [...cachedVariedRates];
	for (const variedRate of variedRates) {
		if (variedRate._endOffsetScale === "infinite") {
			infiniteCount++;
		}

		if (infiniteCount > 1) {
			return "Cannot have any more rates after an infinite duration rate.";
		}

		if (
			variedRate.EndOffset &&
			variedRate.StartOffset &&
			variedRate.EndOffset <= variedRate.StartOffset
		) {
			return "End time for time blocks must be after start time.";
		}

		if (variedRate.FeePerUnit === null || variedRate.FeePerUnit < 0) {
			return "Time block fees must be a positive number, or zero.";
		}

		if (variedRate.MaxFee !== null && variedRate.MaxFee < 0) {
			return "Maximum fee must be a positive number.";
		}

		if (
			variedRate.StartOffset === null ||
			(variedRate.EndOffset === null &&
				variedRate._endOffsetScale !== "infinite")
		) {
			return true;
		}
	}

	return false;
};

export default ({ values, setFieldValue, goTo, wizardProps }) => ({
	id: "varied",
	label: "Rate",
	summary: () => {
		const ratesWithMaxFees = values.cachedVariedRates.filter(
			(rate) => rate.MaxFee
		);

		return [
			{
				id: "varied",
				label: "Rate",
				value: "Variable",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "varied");
					goTo("configuration");
				},
			},
			{
				id: "maximum",
				label: "Maximum Price",
				value: ratesWithMaxFees.length ? "Varied max fee" : "No limit",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "varied");
					goTo("configuration");
				},
			},
			{
				id: "appliedCrossSession",
				label: "Charged Once",
				value: values.appliedCrossSession ? "Yes" : "No",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "varied");
					goTo("configuration");
				},
			},
		];
	},
	render: () => {
		const variedRates = [...values.cachedVariedRates];
		if (values.shapeVariedRates) {
			for (const variedRate of variedRates) {
				for (const scale of wizardProps.variedRateOffsetScales) {
					if (variedRate.EndOffset % scale.multiplier === 0) {
						variedRate._endOffsetScale = scale.value;
						variedRate._scaledEndOffset = Math.round(
							variedRate.EndOffset / scale.multiplier
						);
					}

					if (variedRate.StartOffset % scale.multiplier === 0) {
						variedRate._startOffsetScale = scale.value;
						variedRate._scaledStartOffset = Math.round(
							variedRate.StartOffset / scale.multiplier
						);
					}
				}

				//Handle infinites
				if (variedRate.EndOffset === null) {
					variedRate._endOffsetScale = "infinite";
				}

				//Handle the start of the first varied rate
				if (variedRate.StartOffset === 0) {
					variedRate._startOffsetScale = "minutes";
				}
			}
		}

		/**
		 * Steps through varied rate blocks, flags errors and corrects non-contiguous start/ends between sequential blocks.
		 *
		 * @param {number} startIndex
		 */
		function _justifyVariedRateOffsets(rate, startIndex) {
			for (let index in variedRates) {
				index = parseInt(index);

				//Do not update items before the current block
				if (index < startIndex) {
					continue;
				}

				//Calculate the internal value for the end offset
				const variedRate = variedRates[index];

				if (variedRate._endOffsetScale === "infinite") {
					variedRate.EndOffset = null;
					if (variedRate._invalidEndOffset) {
						variedRate._invalidEndOffset = false;
					}
				} else if (!variedRate._scaledEndOffset) {
					variedRate.EndOffset = null;
				} else {
					const endOffsetScale = wizardProps.variedRateOffsetScales.find(
						(v) => v.value === variedRate._endOffsetScale
					);

					variedRate.EndOffset =
						variedRate._scaledEndOffset * endOffsetScale.multiplier;

					//Validate inline
					variedRate._invalidEndOffset =
						variedRate.EndOffset && variedRate.StartOffset
							? variedRate.EndOffset <= variedRate.StartOffset
							: false;
				}

				//If another item exists after this one, update its start offset to be contiguous
				const nextVariedRate = variedRates[index + 1];
				if (typeof nextVariedRate === "undefined") {
					continue;
				}

				if (variedRate._endOffsetScale === "infinite") {
					nextVariedRate.StartOffset = 0;
					nextVariedRate._scaledStartOffset = 0;
					nextVariedRate._startOffsetScale = "infinite";
					nextVariedRate.EndOffset = 0;
					nextVariedRate._scaledEndOffset = 0;
					nextVariedRate._endOffsetScale = "infinite";
					nextVariedRate._invalidEndOffset = true;
				} else {
					nextVariedRate.StartOffset = variedRate.EndOffset;
					nextVariedRate._scaledStartOffset = variedRate._scaledEndOffset;
					nextVariedRate._startOffsetScale = variedRate._endOffsetScale;

					//If that item's end offset was superceded by the new start offset, raise it one unit to ensure a valid timespan exists
					if (
						nextVariedRate._endOffsetScale !== "infinite" &&
						nextVariedRate.StartOffset >= nextVariedRate.EndOffset
					) {
						nextVariedRate._scaledEndOffset =
							nextVariedRate._scaledStartOffset + 1;
						nextVariedRate._endOffsetScale = variedRate._endOffsetScale;
					}
				}
			}

			if (
				_unitValidForVariedRateDuration(rate.UnitStaggeringScale, rate) ===
				false
			) {
				rate.UnitStaggeringScale = null;
			}
		}

		function _unitValidForVariedRateDuration(unit, variedRate) {
			//All units are valid for infinite rates
			if (variedRate.EndOffset === null) {
				return true;
			}

			//Calculate the number of minutes in the varied rate's duration
			var minuteDuration = Math.floor(
				(variedRate.EndOffset - variedRate.StartOffset) / 60
			);

			if (unit > minuteDuration) {
				return false;
			}

			return true;
		}

		return (
			<>
				<StepTitle>How much will it cost to park on this rate?</StepTitle>
				<Wrapper>
					<Repeater
						items={variedRates}
						add={() => {
							const items = [...values.cachedVariedRates];
							//Retrieve the last existing varied rate
							const lastVariedRate = items[items.length - 1];

							const endOffsetScale = wizardProps.variedRateOffsetScales.find(
								(scale) => scale.value === lastVariedRate._endOffsetScale
							);
							const newEndOffset = lastVariedRate.EndOffset
								? lastVariedRate.EndOffset + 1 * endOffsetScale.multiplier
								: null;
							items.push({
								StartOffset: lastVariedRate.EndOffset,
								_scaledStartOffset: lastVariedRate._scaledEndOffset,
								_startOffsetScale: lastVariedRate._endOffsetScale,
								EndOffset: newEndOffset,
								_scaledEndOffset: newEndOffset
									? Math.ceil(
											endOffsetScale.multiplier
												? newEndOffset / endOffsetScale.multiplier
												: 0
									  )
									: null,
								_endOffsetScale: lastVariedRate._endOffsetScale,
								FeePerUnit: 5,
								UnitStaggeringScale: 15,
								MaxFee: null,
							});
							setFieldValue("cachedVariedRates", items);
						}}
						subtract={() => {
							const items = [...values.cachedVariedRates];
							items.pop();
							setFieldValue("cachedVariedRates", items);
						}}
						template={(rate, index) => (
							<>
								<div style={{ width: "60px", marginRight: "10px" }}>
									<Input
										type="number"
										min="0"
										disabled={true}
										value={
											!rate["_scaledStartOffset"] &&
											rate["_scaledStartOffset"] !== 0
												? ""
												: rate["_scaledStartOffset"]
										}
									/>
								</div>
								<div style={{ width: "100px" }}>
									<Dropdown
										isDisabled={true}
										value={wizardProps.variedRateOffsetScales.find(
											(r) => r.value === rate["_startOffsetScale"]
										)}
									/>
								</div>
								<Separator>to</Separator>
								<div style={{ width: "60px", marginRight: "10px" }}>
									<Input
										type="number"
										min="0"
										disabled={
											wizardProps.variedRateOffsetScales.find(
												(r) => r.value === rate["_endOffsetScale"]
											).value === "infinite"
										}
										value={
											!rate["_scaledEndOffset"] &&
											rate["_scaledEndOffset"] !== 0
												? ""
												: rate["_scaledEndOffset"]
										}
										onChange={(event) => {
											rate["_scaledEndOffset"] = event.target.value
												? parseInt(event.target.value)
												: null;
											_justifyVariedRateOffsets(rate, index);
											const error = invalid(values.cachedVariedRates);
											if (typeof error === String) Alert.error(error);

											setFieldValue("shapeVariedRates", false);
											setFieldValue("cachedVariedRates", variedRates);
										}}
									/>
								</div>
								<div style={{ width: "100px" }}>
									<Dropdown
										options={wizardProps.variedRateOffsetScales}
										value={wizardProps.variedRateOffsetScales.find(
											(r) => r.value === rate["_endOffsetScale"]
										)}
										onChange={(selected) => {
											rate["_endOffsetScale"] = selected.value;

											if (selected.multiplier) {
												rate._scaledEndOffset =
													rate.EndOffset / selected.multiplier;
											} else {
												rate._scaledEndOffset = 0;
											}

											if (
												Math.ceil(rate._scaledEndOffset) !==
												rate._scaledEndOffset
											) {
												rate._scaledEndOffset = Math.ceil(
													rate._scaledEndOffset
												);
											}

											_justifyVariedRateOffsets(rate);

											const error = invalid(values.cachedVariedRates);
											if (error) Alert.error(error);
											setFieldValue("cachedVariedRates", variedRates);
											//only shape varied rates once
											setFieldValue("shapeVariedRates", false);
										}}
									/>
								</div>

								<Separator>costs</Separator>
								<InputWithIcon
									width="100px"
									value={rate.FeePerUnit}
									icon="DollarSign"
									align="left"
									onFormat={(value) => {
										rate.FeePerUnit = value;
										setFieldValue("cachedVariedRates", variedRates);
									}}
								/>
								<Separator>per</Separator>
								<div style={{ width: "100px" }}>
									<Dropdown
										options={wizardProps.variedRateDurations.filter(
											(duration) =>
												_unitValidForVariedRateDuration(duration.value, rate)
										)}
										value={wizardProps.variedRateDurations.find(
											(v) => v.value === rate.UnitStaggeringScale
										)}
										onChange={(selected) => {
											rate.UnitStaggeringScale = selected.value;
											setFieldValue("cachedVariedRates", variedRates);
											//only shape varied rates once
											setFieldValue("shapeVariedRates", false);
										}}
									/>
								</div>
								<Separator>max</Separator>
								<InputWithIcon
									allowNull
									value={rate.MaxFee}
									width="100px"
									icon="DollarSign"
									align="left"
									onFormat={(value) => {
										rate.MaxFee = value || null;
										setFieldValue("cachedVariedRates", variedRates);
									}}
								/>
							</>
						)}
					/>
				</Wrapper>

				<br />
				<br />
				<Wrapper>
					<Toggle
						label="Charge first block of rate once per day?"
						onChange={(value) => setFieldValue("appliedCrossSession", value)}
						checked={values.appliedCrossSession}
					/>
				</Wrapper>
				<p>
					Note: If this is turned on, a user will only be charged the first
					block of this varied rate a maximum of once per calendar day. This
					means that only the first session they have with this rate at the site
					will incur this charge until the following day when it will be applied
					again. This is best used for free periods that only apply once a day.
				</p>
			</>
		);
	},
	isValid: !invalid(values.cachedVariedRates),
});
