import { StepText, StepTitle } from "../WizardLayout";
import { BlockPicker } from "react-color";
import Button from "../../layout/Button";
import ImageImportBox from "../ImageDropBox";
import Input from "../../layout/Input";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import styled from "styled-components";

export const Circle = styled.div`
	border-radius: 20px;
	width: 20px;
	height: 20px;
	float: left;
	margin-right: 10px;
	margin-top: 3px;
`;

export const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 24px;
	text-align: center;
`;

export const companyNameStep = ({
	values,
	goTo,
	setFieldValue,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "companyName",
	label: "Company Name",
	render: () => (
		<div>
			<StepTitle>Company Name</StepTitle>
			<Input
				type="text"
				name="CompanyName"
				onChange={(event) => setFieldValue("CompanyName", event.target.value)}
				value={values.CompanyName || ""}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Cancel
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.CompanyName}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const logoStep = ({
	values,
	goTo,
	setFieldValue,
	next,
	keyStrokeHandler,
	previous,
}) => ({
	id: "logo",
	label: "Logo",
	render: () => (
		<div>
			<StepTitle>Logo</StepTitle>
			<ImageImportBox
				key="logo"
				onChange={(l) => {
					setFieldValue("logoFile", l[0]);
				}}
				shouldCrop={false}
				title={values.Logo ? "Current branding logo" : "Import branding logo"}
				description="*It's recommended that logos with transparent backgrounds be used."
				url={values.Logo}
				initFile={values.logoFile}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.logoFile && values.mode === "add"}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const iconLogoStep = ({
	values,
	goTo,
	setFieldValue,
	next,
	keyStrokeHandler,
	previous,
}) => ({
	id: "iconLogo",
	label: "Icon Logo",
	render: () => (
		<div>
			<StepTitle>Icon Logo</StepTitle>
			<ImageImportBox
				key="iconLogo"
				onChange={(l) => {
					setFieldValue("iconLogoFile", l[0]);
				}}
				shouldCrop={false}
				title={values.Logo ? "Current branding logo" : "Import branding logo"}
				description="*It's recommended that square logos (matching width and height) be used."
				url={values.Logo}
				initFile={values.iconLogoFile}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.logoFile && values.mode === "add"}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const colorStep = ({
	values,
	goTo,
	setFieldValue,
	next,
	keyStrokeHandler,
	previous,
}) => ({
	id: "colour",
	label: "Colours",
	render: () => (
		<div
			style={{
				marginTop: "22px",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<h3>Signage Colours</h3>
			<div>
				*This colour will take effect after you update the web pay config under
				billing details.
			</div>
			<div
				style={{
					marginTop: "22px",
					display: "flex",
					alignItems: "center",
					flexDirection: "row",
					gap: "16px",
				}}
			>
				<div>
					<Label>Primary Colour</Label>
					<BlockPicker
						color={values.PrimaryColour ?? "#3cb98a"}
						onChange={(color) => {
							setFieldValue("PrimaryColour", color.hex);
						}}
						colors={["#3cb98a", "#0093D0", "#FCB034", "#414e57"]}
					/>
				</div>

				<div>
					<Label>Secondary Colour</Label>
					<BlockPicker
						color={values.SecondaryColor ?? "#414e57"}
						onChange={(color) => {
							setFieldValue("SecondaryColor", color.hex);
						}}
						colors={["#414e57", "#0093D0", "#FCB034", "#3cb98a"]}
					/>
				</div>
			</div>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.PrimaryColour}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summary = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	goTo,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		let items = [
			{
				title: "Company Name",
				value: values.CompanyName,
				key: "name",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("companyName");
				},
			},
			{
				title: "Logo",
				value: "(Click edit to upload new logo)",
				key: "logo",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("logo");
				},
			},
			{
				title: "Icon Logo",
				value: "(Click edit to upload new icon logo)",
				key: "iconLogo",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("iconLogo");
				},
			},
			{
				title: "Primary Colour",
				value: (
					<>
						<Circle style={{ backgroundColor: values.PrimaryColour }} />
						{values.PrimaryColour}
					</>
				),
				key: "colour",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("colour");
				},
			},
			{
				title: "Secondary Colour",
				value: (
					<>
						<Circle style={{ backgroundColor: values.SecondaryColor }} />
						{values.SecondaryColor}
					</>
				),
				key: "secondaryColour",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("colour");
				},
			},
		];

		return (
			<div>
				<StepTitle>Summary</StepTitle>

				<SummaryTable items={items} />
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "edit" ? "Update" : "Create"} Brand
				</Button>,
			]}
		/>
	),
});

export const deleteStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "delete",
	label: "Delete",
	render: () => {
		return (
			<div>
				<StepText>
					{values.brandUsage?.Sites?.length
						? `This brand is currently in use by the following sites:\n\n${values.brandUsage?.Sites?.map(
								(s) => s.Name
						  ).join("\n - ")}`
						: "Are you sure you want to delete this brand?"}
				</StepText>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete
				</Button>,
			]}
		/>
	),
});
